<template>
  <div style="width: 100%; height: 100%" class="www">
    测试页面
    <van-button style="margin-bottom: 20px" plain type="info" block @click="back()">BACK</van-button>

    <van-button plain type="info" block @click="back2()">BACK2</van-button>
  </div>
</template>

<script>
import { getWxConfigs } from "@/api/wxApi";
import wx from 'weixin-jsapi'
export default {
  data() {
    return {
      html: '<wx-open-launch-weapp id="launch-btn" username="gh_e3e0d867dd82" path="/pages/index.html"><template><style>.btn { display: flex;align-items: center; }</style><button class="btn">跳转小程序</button></template></wx-open-launch-weapp>',
    };
  },
  methods: {
    stopBack() {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', function() {
            history.pushState(null, null, document.URL);
        });
      },
      //关闭整个浏览器
      back() {  
        wx.closeWindow()
        // document.addEventListener('WeixinJSBridgeReady', function() {
        //     window.WeixinJSBridge.call('closeWindow');
        // }, false);
        // window.WeixinJSBridge.call('closeWindow');
      },
      back2() {  
        window.close()
        // document.addEventListener('WeixinJSBridgeReady', function() {
        //     window.WeixinJSBridge.call('closeWindow');
        // }, false);
        // window.WeixinJSBridge.call('closeWindow');
      },
    wxmini() {
      // 获取密钥
      let url = window.location.href.split("#")[0];
      alert(location.href.split('#')[0])
      getWxConfigs({ url: url }).then((res) => {
        // location.reload()
        wx.config({
          debug: false,
          appId: res.data.appId,
          timestamp: "" + res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
          jsApiList: ["chooseImage", "previewImage"],
          openTagList: ["wx-open-launch-weapp"],
        });
          alert(res.data.signature)
        /* eslint-disable */
        wx.ready(function () {
          // this.html2 = this.html
        });
        wx.error(function (res) {
          alert(res.errCode	)
        });
      });
    },
    initData() {
				let paramData = {
					local_url: window.location.href.split('#')[0],
					version: '4.1.0',
					appKey: 'QTSHE_WECHAT',
					deviceId: Math.random()
				}
				axios.post('https://api.qtshe.com/qtsWeChat/JsApiSign', paramData).then((res) => {
					wx.config({
						debug: false,
						appId: 'wx7357df8d57420ae4',
						timestamp: res.data.timestamp,
						nonceStr: res.data.nonceStr,
						signature: res.data.signature,
						jsApiList: [
							'checkJsApi',
							'openLocation',
							'getLocation',
							'scanQRCode'
						],
						openTagList: [
							'wx-open-launch-weapp',
							'wx-open-launch-app'
						]
					})
				})
			}, 



  },
  created() {
    // this.stopBack()
    // this.wxmini()
    // const oScript = document.createElement("script");
    // oScript.type = "text/javascript";
    // oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    // oScript.onload = this.wxmini;
    // document.body.appendChild(oScript);
  },
  mounted() {

  }

};
</script>

<style>
.www {
  padding: 10px 24px;
}
</style>
